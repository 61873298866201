import { useState, useContext, useCallback, useEffect } from "react";
import { DAppContext } from "./context";
import { Toaster } from "react-hot-toast";
import Banner from "./banner.png";
import Brand from "./brand.png";
import Opensea from "./opensea.svg";
import { ReactComponent as Twitter } from "./twitter.svg";

function App() {
  const [count, setCount] = useState(1);
  const {
    connectBrowserWallet,
    mint,
    userData,
    transactionHash,
  } = useContext(DAppContext);

  const handleCounter = (e) => {
    const { dataset } = e.currentTarget;
    const { pos } = dataset;

    if (pos === "plus" && count < 10) {
      setCount(() => count + 1);
      return;
    }

    if (count - 1 !== 0) {
      setCount(() => count - 1);
    }
  };

  const handleCustomMint = useCallback(() => {
    mint(count);
  }, [count, userData]);

  return (
    <>
      <div className="ineth-links">
        <a
          href="https://opensea.io/collection/in-eth-we-trust-nft"
          target="_blank"
          rel="noreferrer"
          className="ineth-link-item"
        >
          <img src={Opensea} alt="Opensea Link" />
        </a>
        <a
          href="https://twitter.com/InEth_WeTrust"
          target="_blank"
          rel="noreferrer"
          className="ineth-link-item ineth-twitter"
        >
          <Twitter />
        </a>
      </div>
      <div className="ineth-container">
        <div className="ineth--image">
          <img src={Brand} alt="In ETH" />
        </div>
        <div className="ineth-form">
            {!!userData ? (
              <>
                <div className="ineth-form-counter">
                  <button
                    onClick={handleCounter}
                    type="button"
                    className="ineth-btn"
                    data-pos="minus"
                  >
                    -
                  </button>
                  <span> {count} </span>
                  <button
                    onClick={handleCounter}
                    type="button"
                    className="ineth-btn"
                    data-pos="plus"
                  >
                    +
                  </button>
                  <button
                    onClick={handleCustomMint}
                    type="button"
                    className="claim-btn"
                  >
                    Mint
                  </button>
                </div>
                <div className="ineth-claimers">
                  <button
                    onClick={() => mint(3)}
                    type="button"
                    className="claim-btn"
                  >
                    Mint 3
                  </button>
                  <button
                    onClick={() => mint(5)}
                    type="button"
                    className="claim-btn"
                  >
                    Mint 5
                  </button>
                  <button
                    onClick={() => mint(10)}
                    type="button"
                    className="claim-btn"
                  >
                    Mint 10
                  </button>
                </div>
                {transactionHash && (
                  <a
                    href={`https://etherscan.io/tx/${transactionHash}`}
                    target="_blank"
                    rel="noreferrer"
                    className="claim-btn"
                  >
                    See transaction
                  </a>
                )}
              </>
            ) : (
              <button
                type="button"
                className="connect-wallet"
                onClick={connectBrowserWallet}
              >
                Connect Wallet
              </button>
            )}
          </div>
        <div className="ineth--content">
          <p>
            Eth defers arrived b4 that eth merge to support our beloved Eth.
            <br />
            <br />
            Don’t expect genuine moves from me, found my dev drunk at bar b4 the
            launch.
            <br />
            <br />
            Gmi wagmi ngmi already sick of this tbh. The only thing gmi is eth
            that’s why we are here.
            <br />
            <br />
            So it is what it is we got 4.878 handpicked NFTs with eth defers cc0
            <br />
            <br />
            Let’s sit and w8 what Italic Buterin prepared for us
            <br />
            <br />
            So we are joining the #freemint movement. In Eth We Trust NFT is
            free for everyone but if your greediness take over the additional is
            set to donate price of small amount just in case you decide to
            support my small team.
            <br />
            <br />
            In case u dont get one of signs dm me dumpasz bot will explain, thx
            <br />
            <br />
            Effort and quality by 350$ fiver artist
          </p>
          <div className="ineth--full_banner">
            <img src={Banner} alt="" />
            <h1>In eth we trust</h1>
          </div>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default App;
